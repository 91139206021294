@use '../../foundation/' as *;

.s-home {
  &__more {
    margin-top: 2rem;
    text-align: center;

    a {
      display: inline-block;
    }
  }

  .p-section__body--yucaco {
    color: $color_text;

    @include mq-up(md) {
      text-align: center;
    }

    h2 {
      color: $color-blue-gray;
    }

    strong {
      @include font-size(25);
      font-weight: 400;
      color: $color-blue-gray;
    }

    p {
      @include font-size(18);
      @include line-height(34, 18);
      margin-top: 0.5rem;
    }
  }
}
