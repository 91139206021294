@use '../../foundation/' as *;

.swiper-pagination {
  position: relative;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  position: absolute;
  bottom: auto;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  position: relative;
  bottom: 2rem;
  width: 11px;
  height: 11px;
}

.swiper-pagination-bullet-active {
  background-color: $color_white;
}

.swiper-button-next,
.swiper-button-prev {
  top: 40%;
}

.s-works {
  &--index {
    background-color: $color-bg;

    .p-section__body--works {
      margin-top: 0;
    }
  }

  &--show {
    background-color: $color-white;
  }

  &__categorize {
    padding: 0.5rem;
    margin: 2rem 0 0;
    color: $color-bg;
    background-color: $color-white;

    /* @include mq(tablet) { */
    @include mq-up(md) {
      margin: 2rem 0 0 1rem;
    }

    dt {
      float: left;

      /* @include mq(tablet, max) { */
      @include mq-down(md) {
        display: none;
      }
    }

    dd {
      text-align: center;

      /* @include mq(tablet) { */
      @include mq-up(md) {
        padding-right: 6rem;
      }

      a {
        color: $color-bg;
      }
    }
  }

  &__description {
    margin-top: 2rem;

    /* @include mq(tablet, max) { */
    @include mq-down(md) {
      padding: 0 0.5rem;
    }
  }

  &__photo {
    margin-bottom: 1rem;
  }

  &__description {
    text-align: left;
  }
}

#cis-load-img {
  img {
    width: 50px;
  }
}
