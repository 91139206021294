@use '../../foundation/' as *;

.u-font10 {
  @include font-size(10);
}

.u-font11 {
  @include font-size(11);
}

.u-font12 {
  @include font-size(12);
}

.u-font13 {
  @include font-size(13);
}

.u-font14 {
  @include font-size(14);
}

.u-font15 {
  @include font-size(15);
}

.u-font16 {
  @include font-size(16);
}

.u-font17 {
  @include font-size(17);
}

.u-font18 {
  @include font-size(18);
}

.u-font19 {
  @include font-size(19);
}

.u-font20 {
  @include font-size(20);
}
