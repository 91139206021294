@use 'sass:math';

@mixin font-size($size, $base: 16) {
  font-size: $size + px;
  font-size: math.div($size, $base) + rem;
}

@mixin font-size-mb($size, $base: 14) {
  font-size: $size + px;
  font-size: math.div($size, $base) + rem;
}
