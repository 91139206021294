@use '../../foundation/' as *;

.p-form {
  &__body {
    padding: 1.5rem;
    margin-top: 2rem;
    color: #333;
    background-color: $color_white;
    border-radius: 5px;

    /* @include mq(desktop) { */
    @include mq-up(lg) {
      min-height: 38rem;
    }

    p {
      margin: 0 0 1rem;

      span {
        @include font-size(14);
      }
    }

    .mw_wp_form .horizontal-item + .horizontal-item {
      margin-left: 5px;
    }

    .c-button {
      padding: 0.75rem 5rem;
      margin-bottom: 0 auto 0;
    }
  }

  &__privacy {
    padding: 2rem 1.5rem;
    margin-top: 2rem;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;

    /* @include mq(desktop) { */
    @include mq-up(lg) {
      max-height: 38rem;
    }
    /* @include mq(tablet, max) { */
    @include mq-down(md) {
      max-height: 15rem;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #eee;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 5px;
    }

    p {
      @include font-size(14);
      margin: 1rem 0;
    }
  }

  &__label--require {
    margin-left: 0.5rem;
  }

  &__input,
  &__textarea {
    width: 100%;
    padding: 0.5rem;
    margin: 0.25rem 0;
    border: 1px solid #ccc;
  }
}

.mwform-checkbox-field {
  margin-right: 0.4rem;

  input {
    margin-right: 0.25rem;
  }
}
