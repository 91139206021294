.c-loader,
.c-loader:after {
  width: 7em;
  height: 7em;
  border-radius: 50%;
}

.c-loader {
  position: relative;
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.75em solid rgba(#000, 0.1);
  border-right: 0.75em solid rgba(#000, 0.1);
  border-bottom: 0.75em solid rgba(#000, 0.1);
  border-left: 0.75em solid #ccc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
