@use '../../foundation/' as *;

.p-drawer {
  &__menu {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: $font-family-sans-serif;

    li {
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    a {
      display: block;
      padding: 0;
      line-height: 40px;
      color: $color_white;
      transition: 0.3s linear;
      transition-property: all;

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      i {
        margin: 0 0 0 10px;
      }
    }
  }

  &__button {
    position: fixed;
    top: 0;
    left: 20px;
    z-index: 110;
    color: $color-white;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
