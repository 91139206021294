@use 'sass:map';
@use '../variable/breakpoint' as *;

@mixin mq-down($breakpoint: $default-breakpoint) {
  @if map-has-key($breakpoint-down, $breakpoint) {
    @media #{map-get($breakpoint-down, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$_breakpoint-down` map.";
  }
}
