@use '../../foundation/' as *;

.p-footer {
  &__pagetop {
    position: relative;

    a {
      position: absolute;
      top: -55px;
      left: 50%;
      display: block;
      width: 50px;
      height: 50px;
      margin-left: -25px;
      line-height: 50px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      transition: 0.3s linear;

      /* @include mq(desktop) { */
      @include mq-up(lg) {
        top: -60px;
        width: 60px;
        height: 60px;
        margin-left: -30px;
        line-height: 60px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &__copyright {
    color: $color-white;

    /* @include mq(desktop) { */
    @include mq-up(lg) {
      transform: translateY(10px);
    }
  }
}

.s-works--show,
.s-information {
  .p-footer {
    &__pagetop {
      a {
        background-color: rgba(0, 0, 0, 0.3);

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &__copyright {
      color: $color-text;
    }
  }
}
