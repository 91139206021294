/*! purgecss start ignore */
.swiper-container {
  overflow: hidden;
}

.swiper-pagination {
  width: 100%;

  span {
    margin: 0 0.25rem;
  }
}
/*! purgecss end ignore */
