@use '../../foundation/' as *;

.p-section__body {
  margin-top: 2rem;
  color: $color-white;
}

.p-section__inner {
  margin: auto;
  text-align: left;
}

.p-section {
  text-align: center;

  &--concept {
    color: $color_brown;
    background-color: $color_white_brown;
  }

  &--information {
    padding: 3rem 1rem;
    margin-top: 3rem;
    color: $color_white;
    text-align: left;
    background-color: $color_brown;
  }

  &--yucaco {
    color: $color_brown;
    background-color: $color_white_brown;
  }

  &--diary,
  &--contact {
    color: $color_white;
    background-color: $color_bg2;
  }

  &__inner--works-show {
    padding: 3rem 0 0;
    text-align: center;

    @extend .p-section__inner;
  }

  &__inner--concept {
    padding: 3rem 0 0;
    text-align: center;

    @extend .p-section__inner;
  }

  &__body {
    &--works,
    &--about-us,
    &--diary {
      @extend .p-section__body;
    }

    &--yucaco {
      @extend .p-section__body;
    }
  }
}

// <- Mobile L
/* @include mq(tablet, max, true) { */
@include mq-down(md) {
  .p-section {
    &__body {
      padding: 0 1rem;

      &--works {
        padding: 0 0.5rem;
      }

      &--works-show {
        padding: 1.5rem 0;
      }

      &--diary {
        padding: 0 0.5rem;
      }

      &--about-us {
        padding: 0 1rem;
      }

      &--contact {
        padding: 0 0.5rem;
      }
    }

    &__inner {
      padding: 3rem 0;

      &--concept {
        padding: 3rem 0 0;
      }
    }
  }
}

/* @include mq(mobile_l) { */
@include mq-down(sm) {
  .p-section {
    &__body {
      padding: 0 1rem;

      &--works {
        /* @include col(2, 1rem); */
      }

      &--works-show {
        /* @include col(3, 0.5rem); */
        padding: 1.5rem 0;
      }

      &--diary {
        /* @include col(2, 1rem); */
      }

      &--about-us {
        /* @include col(3, 1rem); */
      }
    }

    &__inner {
      &--works-show {
        padding: 3rem 1rem;
      }
    }
  }
}

// Tablet ->
/* @include mq(tablet) { */
@include mq-up(md) {
  .p-section {
    &__body {
      padding: 0 1rem;

      &--works {
        @include col(3, 1rem);
      }

      &--works-show {
        @include col(3, 1rem);
        padding: 1.5rem 0;
      }

      &--diary {
        @include col(2, 1rem);
      }

      &--about-us {
        @include col(3, 1rem);
        padding: 0 1rem;
      }

      &--contact {
        padding: 0 0.5rem;
      }

      &--yucaco {
        margin-top: 1rem;
      }
    }

    &__inner {
      padding: 3rem 0;

      &--works-show {
        padding: 0 1.5rem;
      }

      &--concept {
        padding: 3rem 0 0;
      }
    }
  }
}

// Desktop ->
/* @include mq(desktop) { */
@include mq-up(lg) {
  .p-section {
    &--information {
      padding: 3rem 6rem;
    }

    &__body {
      padding: 0 6rem;

      &--works {
        @include col(3, 1rem);
        padding: 0;
      }

      &--works-show {
        @include col(3, 1rem);
        padding: 1.5rem 0;
      }

      &--diary {
        @include col(4, 1rem);
        padding: 0;
      }

      &--about-us {
        @include col(3, 1rem);
        padding: 0;
      }

      &--contact {
        @include col(2, 1rem);
        padding: 0;

        > .col {
          min-height: 680px;
        }
      }
    }

    &__inner {
      padding: 3rem 0;

      &--works-show {
        padding: 3rem;
      }

      &--concept {
        padding: 3rem 0 0;
      }
    }
  }
}

// Desktop L ->
/* @include mq(desktop_l) { */
@include mq-up(lg-xl) {
  .p-section {
    // &--concept {
    //   background: url('/images/bg_concept.jpg') 0 0 repeat;
    // }

    &--information {
      padding: 3rem 1.5rem;
    }

    &__body {
      &--works {
        /* padding: 0 0 2rem; */
      }

      &--diary {
        /* padding: 0 0 1rem; */
      }

      &--about-us {
      }

      &--contact {
      }
    }

    &__inner {
      max-width: 1000px;
      padding: 5rem 0;

      &--works-show {
        max-width: 1000px;
        padding: 3rem 0;
      }

      &--concept {
        max-width: 1080px;
        padding: 3rem 40px 0;
        background-color: $color_white_brown;
      }
    }
  }
}
