@use '../../foundation/' as *;

.c-button {
  display: inline-block;
  padding: 0.75em 1em;
  margin: 0 0;
  font-size: 0.9rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #68684d;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: 0.3s linear;

  -webkit-appearance: none;

  &:hover {
    background-color: #1c1814;
  }

  &__more {
    position: relative;
    display: block;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    opacity: 1;
    transition: 0.3s linear;
    transition-property: all;

    /* @include mq(desktop) { */
    @include mq-up(lg) {
      height: 80px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
