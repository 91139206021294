@use '../../foundation/' as *;

.inview {
  opacity: 0;
  transition: 1s;
  transform: translate(0, 50px);
}

.inview.is-show {
  opacity: 1;
  transform: translate(0, 0);
}
