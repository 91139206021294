// @use 'node_modules/@fancyapps/ui/dist/fancybox';
// @use 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
// @use 'node_modules/js-tabs/src/js-tabs-base';
// @use 'node_modules/scroll-hint/scss/scroll-hint.scss';

/*
 * foundation
 */
// @use './foundation/' as *;
/*! purgecss start ignore */
@use './foundation/base/sanitize';
@use './foundation/base/base';
@use 'node_modules/vegas/dist/vegas.css';
@use 'node_modules/swiper/swiper-bundle.css';
@use './foundation/vendor/acf' as v-acf;
@use './foundation/vendor/drawer' as v-drawer;
@use './foundation/vendor/fancybox' as v-fancybox;
@use './foundation/vendor/particle' as v-particle;
@use './foundation/vendor/swiper' as v-swiper;
@use './foundation/vendor/inview' as v-inview;
/*! purgecss end ignore */

/*
 * layout
 */
@use './layout/header' as l-header;
@use './layout/main' as l-main;
@use './layout/footer' as l-footer;

/*
 * object.
 */
@use "object/component/_buttons.scss";
@use "object/component/_label.scss";
@use "object/component/_loader.scss";
@use "object/component/_table.scss";;
@use "object/project/_card.scss";
@use "object/project/_drawer.scss";
@use "object/project/_footer.scss";
@use "object/project/_form.scss";
@use "object/project/_header.scss";
@use "object/project/_list.scss";
@use "object/project/_map.scss";
@use "object/project/_section.scss";
@use "object/project/_typography.scss";;
@use "object/scope/_concept.scss";
@use "object/scope/_home.scss";
@use "object/scope/_information.scss";
@use "object/scope/_works.scss";
@use "object/scope/_yucaco.scss";;
@use "object/utility/_align.scss";
@use "object/utility/_float.scss";
@use "object/utility/_font.scss";
@use "object/utility/_is.scss";
@use "object/utility/_margin.scss";
@use "object/utility/_padding.scss";
@use "object/utility/_text.scss";;
