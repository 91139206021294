@use '../../foundation/' as *;

#p-map__container {
  position: relative;
  width: 100%;
  height: 400px;

  /* @include mq(medium, max) { */
  @include mq-down(sm) {
    height: 300px;
  }
}

.p-map {
  position: relative;
}

.p-map__zoom {
  position: absolute;
  top: auto !important;
  right: 0;
  bottom: 0;
  left: auto !important;
}

#p-map__zoom--in,
#p-map__zoom--out {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
  background-color: rgba(243, 87, 75, 0.7);
  background-image: url('../images/map_controller.svg');
  background-repeat: no-repeat;
  background-size: 32px 64px;

  .no-touch &:hover {
    background-color: rgba(243, 87, 75, 1);
  }

  // @include MQ(M) {
  //  margin-left: 50px;
  // }
}

#p-map__zoom--in {
  margin-bottom: 1px;
  background-position: 50% 0;

  // @include MQ(M) {
  //  margin-top: 50px;
  // }
}

#p-map__zoom--out {
  margin-bottom: 30px;
  background-position: 50% -32px;
}
