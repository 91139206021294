@use '../' as *;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  @extend %font16;

  height: 100%;
  line-height: $line-height-base;
  scroll-behavior: smooth;
}

body {
  /* メトリクスカーニングを指定する */
  height: 100%;
  font-family: $font-family-base;
  font-feature-settings: 'palt';
  font-kerning: normal;
  color: $color_text;
  letter-spacing: $letter-spacing;
  background-color: $color-white-brown;

  /* アンチエイリアスを指定する */
  // -webkit-font-smoothing: antialiased;
  // -webkit-font-smoothing: subpixel-antialiased;
  // -moz-osx-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.3em;
  color: inherit;
}

ul,
ol {
  padding: 0;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

dd {
  margin-left: 0;
}

able {
  width: 100%;
}

th {
  text-align: left;
}

a {
  color: $color_link;
  text-decoration: none;
  outline: none;
  transition: $duration;

  &:visited {
    color: $color_link--visited;
  }

  &:hover {
    color: $color_link--hover;
  }
}

/**
 * フルードイメージにします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

button {
  outline: none;
}

/**
 * IEでSVGがフルードイメージにならない不具合があります。
 * 属性値が`.svg`で終わる要素に適応します。
 */
[src$='\.svg'],
[data$='\.svg'] {
  width: 100%;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin: 0;
}
