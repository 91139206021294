@use '../../foundation/' as *;

.c-label {
  @include font-size(10);
  padding: 0.15rem 0.3rem;
  border-radius: 0.25rem;

  &--default {
    color: $color_brown;
    background-color: $color_white;

    @extend .c-label;
  }

  &--warning {
    color: $color_white;
    background-color: $color_warning;

    @extend .c-label;
  }
}
