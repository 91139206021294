@use 'sass:map';
@use '../variable/breakpoint' as *;

@mixin mq-up($breakpoint: $default-breakpoint) {
  @if map-has-key($breakpoint-up, $breakpoint) {
    @media #{map.get($breakpoint-up, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$_breakpoint-up` map.";
  }
}
