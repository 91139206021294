@use '../' as *;

.v-drawer-nav {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 240px;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
}

.v-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.v-drawer-open .v-drawer-overlay {
  display: block;
}

.v-drawer--left .v-drawer-nav {
  left: -240px;
  -webkit-transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.v-drawer--left.v-drawer-open .v-drawer-nav,
.v-drawer--left .v-drawer-hamburger,
.v-drawer--left.v-drawer-open .v-drawer-navbar .v-drawer-hamburger {
  left: 0;
}

.v-drawer--left.v-drawer-open .v-drawer-hamburger {
  left: 240px !important;
}

.v-drawer-hamburger {
  @include font-size(10);
  position: fixed;
  bottom: 3rem;
  z-index: 104;
  box-sizing: content-box;
  display: block;
  width: 2rem;
  padding: 1rem 0.75rem 0;
  font-family: $font-family-sans-serif;
  line-height: 27px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 0 8px 8px 0;
  outline: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.v-drawer-hamburger:hover {
  cursor: pointer;
}

.v-drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 10px;
}

.v-drawer-hamburger-icon:before,
.v-drawer-hamburger-icon:after {
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.v-drawer-hamburger-icon:before,
.v-drawer-hamburger-icon:after {
  position: absolute;
  top: -10px;
  left: 0;
  content: ' ';
}

.v-drawer-hamburger-icon:after {
  top: 0;
}

.v-drawer-open .v-drawer-hamburger {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.v-drawer-open .v-drawer-hamburger-icon {
  background-color: transparent;
}

.v-drawer-open .v-drawer-hamburger-icon:before,
.v-drawer-open .v-drawer-hamburger-icon:after {
  top: 0;
}

.v-drawer-open .v-drawer-hamburger-icon:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.v-drawer-open .v-drawer-hamburger-icon:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@include mq-up(lg-xl) {
  .v-drawer--sidebar .v-drawer-hamburger {
    display: none;
    visibility: hidden;
  }

  .v-drawer--sidebar .v-drawer-nav {
    position: fixed;
    display: block;
    width: 150px;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .v-drawer--sidebar.v-drawer--left .v-drawer-nav {
    left: 0;
    border: none;
  }
}
