@use '../../foundation/' as *;

.p-list {
  &-information {
    padding: 2rem 0;

    &__date {
      float: left;
      padding-top: 0.75rem;
    }

    &__title {
      padding: 0.75rem 0.5rem 0.75rem 5rem;
      font-weight: 700;
      border-bottom: 1px solid $color_white;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  &-work {
    margin-top: 2rem;

    /* @include mq(tablet) { */
    @include mq-up(md) {
      margin-top: 1rem;
    }

    &__title {
      @include font-size(16);
      padding: 0 0 0.75rem;
      font-weight: 800;
      text-align: center;
    }

    &__description {
      @include font-size(14);
      background-color: rgba(255, 255, 255, 1);
      transition: 0.3s linear;

      a {
        position: relative;
        display: block;
        padding: 0.75rem 1.75rem 0.75rem 0.75rem;
        color: $color_brown;

        &:after {
          position: absolute;
          top: 45%;
          right: 0.75rem;
          width: 10px;
          height: 10px;
          margin: 0 0 0 10px;
          content: '';
          border-top: 3px solid $color_bg;
          border-right: 3px solid $color_bg;
          border-radius: 1px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &--hover {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  &-diary {
    position: relative;
    margin-bottom: 0.5rem;
    overflow: hidden;

    &__picture {
      position: relative;
      width: 100%;
      overflow: hidden;

      a {
        display: block;
        height: 230px;
        padding: 0.5rem;
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        transition: 0.3s linear;
        transition-property: all;

        /* @include mq(tablet, max) { */
        @include mq-down(md) {
          height: 250px;
        }
        /* @include mq(mobile_l, max) { */
        /* @include mq-down(sm) { */
        /*   height: 198px; */
        /* } */
        /* @include mq(mobile, max) { */
        @include mq-down(sm) {
          height: 174px;
        }
        /* @include mq(mobile_s, max) { */
        @include mq-down(xs) {
          height: 146px;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &__message {
      @include font-size(13);
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      line-height: 1.5;
      color: #fff;
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0) 100%
      );

      a {
        display: block;
        padding: 0.5rem;
      }
    }
  }

  &-company {
    dt {
      @include font-size(20);
      margin-bottom: 0.5rem;
      font-family: $font-family-sans-serif;
    }

    dd {
      @include font-size(14);
      margin-top: 0.5rem;
      line-height: 1.8;
    }
  }

  &-profile {
    /* @include mq(tablet, max) { */
    @include mq-down(md) {
      margin-top: 2rem;
    }

    &__name {
      margin-top: 0.5rem;
    }

    &__summary {
      @include font-size(12);
      margin-top: 0.5rem;
    }
  }

  &-faq {
    margin: 2rem auto;

    dt {
      font-weight: bold;

      &:before {
        content: 'Q.';
      }
    }

    dd {
      @include font-size(14);
      margin-top: 0.5rem;
      line-height: 1.8;
    }
  }
}
