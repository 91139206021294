@use 'sass:math';

@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
  zoom: 1;
}

@mixin col($num, $margin: 2rem) {
  @include clearfix;
  margin-left: #{-$margin};
  $margin_num: $num - 1;
  $margin_width: #{$margin * $num};

  > .col,
  > li {
    float: left;
    width: -webkit-calc((100% - #{$margin_width}) / #{$num});
    width: calc((100% - #{$margin_width}) / #{$num});
    margin-left: #{$margin};
  }
}
