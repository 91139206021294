@use '../../foundation/' as *;

%title {
  @include font-size(30);
  margin: 0;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  line-height: 1em;
  line-height: 1;
  color: $color_blue-gray;
  text-align: center;

  /* @include mq(tablet) { */
  @include mq-up(md) {
    @include font-size(50);
  }
}

.s-yucaco {
  &__title {
    @extend %title;
    padding: 5rem 0 0;
    background-color: $color_white_brown;

    @include mq-up(md) {
      padding: 7.5rem 0 0;
    }

    &:after {
      @include font-size(20);
      display: block;
      margin-top: 1rem;
      font-weight: 700;
      color: $color_text;
      content: '〜ユカコ システム〜';
    }
  }

  &__section-inner {
    @include font-size(18);
    width: 100%;
    padding: 0 1rem;

    @media screen and (min-width: 890px) {
      width: 890px;
      margin: auto;
    }
  }

  &__catch {
    @include font-size(25);
    @include line-height(32, 25);
    display: block;
  }

  &__cols {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5px;

    @media screen and (min-width: 890px) {
      flex-direction: unset;
      padding: 15px 0 0 15px;
    }
  }

  &__col {
    width: 100%;
    padding: 0 5px 5px 0;
    margin-top: 1.5rem;
    background-color: #fff;
    border: 5px solid #cac0b6;
    border-radius: 10px;

    @media screen and (min-width: 890px) {
      position: relative;
      width: 260px;
      height: 265px;
      padding: unset;
      background-color: #fff;
    }

    dl {
      width: calc(100% + 10px);
      padding: 1.5rem 1rem 1rem;
      margin: -10px auto auto -10px;
      color: #fff;
      background-color: $color_blue_gray;
      border-radius: 10px;

      @media screen and (min-width: 890px) {
        position: absolute;
        top: -15px;
        left: -15px;
        width: 260px;
        height: 265px;
        margin: unset;
        background-color: $color_blue_gray;
        border-radius: 10px;
      }
    }

    dt {
      @include font-size(20);
      @include line-height(32, 25);
      margin-inline: auto;
      font-weight: 700;
      max-inline-size: max-content;

      @media screen and (min-width: 890px) {
        @include font-size(25);
        text-align: center;
      }
    }

    dd {
      @include font-size(14);
      @include line-height(22, 14);
      margin-top: 1.5rem;
    }
  }

  &__section1 {
    padding: 3rem 0 6.5rem;
    background-color: $color_white_brown;

    p {
      @include line-height(34, 18);
      margin-top: 1rem;
    }

    strong {
      color: $color_blue-gray;
    }
  }

  &__section2 {
    padding: 2.5rem 0;
    color: #fff;
    text-align: center;
    background-color: $color_blue_gray;

    h2 {
      @extend %title;
      color: #fff;
    }

    strong {
      margin-top: 2rem;
    }

    figure {
      margin: 1rem auto 0;
    }

    figcaption {
      margin-top: 1rem;
      text-align: left;
    }

    ul {
      padding: 1rem 1.5rem;
      margin-top: 2rem;
      color: $color_text;
      text-align: left;
      list-style: none;
      background-color: #fff;
      border: 1px solid #333;

      li {
        padding: 0.25rem 0;
        border-bottom: 1px solid $color_text;

        &:last-of-type {
          border: 0;
        }
      }
    }
  }

  &__section3 {
    padding: 3rem 0 6.5rem;
    background-color: $color_white_brown;

    h2 {
      @extend %title;
      margin-top: 5.5rem;
      font-weight: normal;

      strong {
        @include font-size(30);
        display: block;
        margin-top: 0.5rem;

        @include mq-up(md) {
          @include font-size(43);
          display: inline-block;
          padding-left: 1rem;
          margin-top: 0;
          font-weight: bold;
        }
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
