@use '../foundation/' as *;

.l-footer {
  padding: 30px 0;
  font-family: $font-family-sans-serif;
  text-align: center;
  background-color: $color_blue_gray;
}

.s-home {
  .l-footer {
    color: $color-white;
    background-color: $color_blue_gray;
    /* background-color: rgba(0, 0, 0, 0.7); */
  }
}
