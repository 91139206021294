@use '../../foundation/' as *;

.p-header {
  width: 100%;
  height: 100%;

  &__title {
    @include font-size(12);
    position: fixed;
    top: 5px;
    right: 10px;
    z-index: 90;
    font-weight: normal;
    color: $color-white;
  }

  &__logo {
    position: absolute;
    top: calc(50% - 34px);
    width: 100%;
    text-align: center;

    @include mq-up(sm) {
      top: calc(50% - 40px);
    }

    @include mq-up(md) {
      top: calc(50% - 45px);
    }

    @include mq-up(lg-xl) {
      width: calc(100% - 150px);
      margin-left: 150px;
    }

    img {
      width: 300px;
      margin: auto;

      @include mq-up(sm) {
        width: 350px;
      }

      @include mq-up(md) {
        width: 400px;
      }
    }
  }

  &__scroll {
    position: absolute;
    bottom: 100px;
    left: 50%;
    color: $color-white;
    text-align: center;
    transform: translateX(-50%);

    @include mq-up(lg-xl) {
      left: calc(50% + 75px);
    }
  }

  &__mouse {
    position: absolute;
    top: 30px;
    width: 28px;
    height: 40px;
    margin-left: -14px;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }

  &__wheel {
    position: absolute;
    top: 23px;
    left: 50%;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: $color-white;
    border-radius: 50%;
    animation: scroll_mobile 2s infinite;

    /* @include mq(tablet, min, true) { */
    @include mq-up(md) {
      top: 5px;
      animation: scroll_desctop 2s infinite;
    }
  }
}

.vegas-slide {
  position: fixed;
  top: 0;
  z-index: -100;
}

@keyframes scroll_desctop {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    -webkit-transform: translate(0, 15px);
    transform: translate(0, 15px);
  }

  100% {
    opacity: 0;
  }
}

@keyframes scroll_mobile {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
  }

  100% {
    opacity: 0;
  }
}
