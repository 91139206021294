.u-pt0 {
  padding-top: 0!important;
}

.u-pt05 {
  padding-top: .5rem!important;
}

.u-pt1 {
  padding-top: 1rem!important;
}

.u-pt2 {
  padding-top: 2rem!important;
}

.u-pt3 {
  padding-top: 3rem!important;
}

.u-pt4 {
  padding-top: 4rem!important;
}

.u-pt5 {
  padding-top: 5rem!important;
}

.u-pr0 {
  padding-right: 0!important;
}

.u-pr05 {
  padding-right: .5rem!important;
}

.u-pr1 {
  padding-right: 1rem!important;
}

.u-pr2 {
  padding-right: 2rem!important;
}

.u-pr3 {
  padding-right: 3rem!important;
}

.u-pb0 {
  padding-bottom: 0!important;
}

.u-pb05 {
  padding-bottom: .5rem!important;
}

.u-pb1 {
  padding-bottom: 1rem!important;
}

.u-pb2 {
  padding-bottom: 2rem!important;
}

.u-pb3 {
  padding-bottom: 3rem!important;
}

.u-pb4 {
  padding-bottom: 4rem!important;
}

.u-pb5 {
  padding-bottom: 5rem!important;
}

.u-pl0 {
  padding-left: 0!important;
}

.u-pl05 {
  padding-left: .5rem!important;
}

.u-pl1 {
  padding-left: 1rem!important;
}

.u-pl2 {
  padding-left: 2rem!important;
}

.u-pl3 {
  padding-left: 3rem!important;
}
