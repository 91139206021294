@use '../../foundation/' as *;

.p-cards {
  padding: 0;

  /* @include mq(tablet) { */
  @include mq-up(md) {
    margin: 3rem 0;
  }
}

.p-card-information {
  top: 0;
  left: 0;
  list-style: none;
  opacity: 0;
  transform: translateY(100px);

  &__inner {
    padding: 1rem;
    margin: 0.5rem;
    background-color: $color_white;
  }

  &__title {
    @include font-size(16);
    padding: 1rem 0.75rem;
    font-weight: normal;
    line-height: 1.3;
    color: $color_white;
    background-color: #68818b;
  }

  &__content {
    padding: 0.5rem;

    img {
      margin: 0.5rem 0;
    }
  }

  &__date {
    padding: 0.25rem 0.5rem;
    color: #666;
    text-align: right;
    border-top: 1px solid #ccc;
  }

  &.show {
    opacity: 1;
    transition: all 0.3s, top 1s;
    transform: translateY(0);
  }
}
