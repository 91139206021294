@use '../../foundation/' as *;

%headings-1 {
  @include font-size(30);
  margin: 0;
  font-family: $font-family-sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-weight: 400;
  line-height: 1em;
  text-align: center;

  /* @include mq(tablet) { */
  @include mq-up(md) {
    @include font-size(50);
  }
}

%headings-2 {
  @include font-size(16);
  margin: 0;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-align: center;

  /* @include mq(tablet) { */
  @include mq-up(md) {
    @include font-size(20);
  }
}

.p-headings {
  &-1 {
    color: $color_white;

    @extend %headings-1;

    &--concept {
      @extend %headings-1;
    }

    &--privacy {
      @include font-size(22);
      margin-bottom: 1rem;

      @extend %headings-1;
    }

    &--information {
      @include font-size(30);
      margin-top: 1rem;
      font-weight: 800;
      color: $color-brown;

      @extend %headings-1;
      /* @include mq(tablet) { */
      @include mq-up(md) {
        @include font-size(50);
        margin-top: 5rem;
      }
    }

    &--yucaco {
      @extend %headings-1;
      color: #465760;
    }

    &--pages,
    &--works--index {
      @include font-size(30);
      margin-top: 1rem;
      font-weight: 800;
      color: $color-white;

      @extend %headings-1;

      /* @include mq(tablet) { */
      @include mq-up(md) {
        @include font-size(50);
        margin-top: 5rem;
      }
    }

    &--works--show {
      @include font-size(30);
      margin-top: 1rem;
      font-weight: 800;
      color: $color-bg;

      @extend %headings-1;

      /* @include mq(tablet) { */
      @include mq-up(md) {
        @include font-size(50);
        margin-top: 5rem;
      }
    }
  }

  &-2 {
    @extend %headings-2;

    &--concept {
      line-height: 5rem;

      @extend %headings-2;
    }

    &--diary {
      @include font-size(12);
      font-weight: 400;
      color: $color_white;

      @extend %headings-2;
    }

    &--access {
      @include font-size(30);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      padding: 1em 1em;
      font-family: $font-family-sans-serif;
      font-weight: 400;
      color: $color-bg;
      text-align: center;
    }

    &--yucaco {
      @include font-size(20);
      margin-top: 0.5rem;
      color: $color_blue-gray;
      text-align: center;
    }
  }
}
