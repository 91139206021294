@use '../../foundation/' as *;

.s-information {
  background: $color_white_brown;

  &__nav {
    text-align: right;
  }
}

img.alignright {
  float: right;
  margin-left: 0.75rem;
}

img.alignleft {
  float: left;
  margin-right: 0.75rem;
}

.pg-canvas {
  position: fixed;
}
